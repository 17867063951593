export const questions = [
  "What will life be like 50 years from now?",
  "What will the world be like 1,000 years from now?",
  "What will the world be like in millions of years?",
  "In what towns will life be the same as it is today 30 years from now?",
  "Can humans live on Earth a billion years from now?",
  "How will our era be misremembered 2,000 years from now?",
  "If the world still exists 200 years from now, what will people ask?",
  "What if life on Earth ends 100 years from now?",
  "Imagine a life entirely different from the one you now lead, what would it be?",
  "How would you live your life if you knew that the universe will collapse five or six years from now?",
  "If you could relive your life from birth knowing what you know now, what would you do differently and why?",
  "Does the end justify the means?",
  "Is it possible that the creation days of the Earth were the same as 24-hour Earth days?",
  "What if days were 48 hours long?",
  "Is it true that Earth days were roughly 22 hours long 600 million years ago?",
  "Will dating be any different in 2030 versus today?",
  "How long could the average 40-year-old man today expect to live?",
  "Has the first person to live to 200 years old been born yet?",
  "Will someone born today live to become 1,000 years old?",
  "What would be the social and economic consequences of people living to be 1,000 years old?",
  "Will YouTube live for more than a 1,000 years?",
  "How can I live for 1,000 years?",
  "What will happen to our pace of life if we are going to live up to 1,000 years old?",
  "How did people live 1,000 years ago?",
  "Has science found the secret to immortality?",
  "Humans will soon be able to live to an age of 1,000 years; what will be the impact?",
  "Why did Stephen Hawking say that the human race has less than 1,000 years to live?",
  "What will entertain humans 1,000 years from now?",
  "What would happen to the mind of a regular human if he were made completely immortal and put into an impenetrable coffin for 10,000 years?",
  "What will the human species look like in 1,000,000 years?",
  "What did humans look like 60,000 years ago?",
  "If you were immortal, where and how would you see yourself in 1,000,000 years?",
  "Was the duration of 1 hour 10,000 years ago the same as 1 hour now? Will it still be the same in 10,000 years?",
  "Would you rather live one 1,000-year life or ten 100-year lives?",
  "Can humanity create God in the future?",
  "How can humanity create a fair world?",
  "What artifacts of the 21st century will survive after humanity is gone?",
  "Should we be worried about the future?",
  "What worries you most about the future?",
  "How do I not worry about the future?",
  "What are the most worrying unknowns?",
  ""
];
